import {defineStore} from 'pinia';
import {parse, stringify} from 'zipson/lib';
import {route} from '@/services/uri.service';
import axios from 'axios';
import {loaderComposable} from '@/composables/global.composables';
import {appAlert, appAlertRequestError} from '@/composables/alert.composables';

export const authModule = defineStore('auth-module', {
    state: () => ({
        userAuth: {},
        credentials: {
            username: null,
            password: null,
        },
        token: {},
        meta: {},
        authProfile: [],
        authActivityLogs: [],
        loader: loaderComposable.requestStatus,
        errors: [],
        isOtpSent: false,
        timerRemaining : process.env.VUE_APP_OTP_EXPIRATION,
        interval : null,
    }),

    getters: {
        getUserAuth: state => state['userAuth'],
        getCredentials: state => state['credentials'],
        getToken: state => state['token'],
        getMeta: state => state['meta'],
        getAuthProfile: state => state['authProfile'],
        getActivityLogs: state => state['authActivityLogs'],
        getLoader: state => state['loader'],
        getErrors: state => state['errors'],
        getIsOtpSent: state => state['isOtpSent'],
        getTimerRemaining: state => state['timerRemaining'],
        getInterval: state => state['interval'],
    },

    actions: {
        /**
         * @param payload
         * @param config
         */
        login: function (config = {}) {
            let routeLogin = route.login();
            this.loader = true;
            return axios.post(`${routeLogin}`, this.credentials, config).then((r) => {
                const response = r.data;
                this.token = response?.data?.token ?? null;
                this.userAuth=response?.data?.user ?? null
                // this.userAuth = response?.data ?? null
                this.meta = response.meta;
                this.credentials = {
                    username: null,
                    password: null,
                };
                this.loader = false;
                return true;
            }).catch((er) => {
                appAlertRequestError(er).response();
                this.loader = false;
                return false;
            });
        },

        /**
         * @param payload
         * @param config
         */
        logout(payload = {}, config = {}) {
            this.loader = true;
            const routeLogout = route.logout();
            return axios.post(routeLogout).then((r) => {
                this.loader = false;
                return true;
            }).catch((er) => {
                console.log('Something ,', er);
                this.loader = false;
                appAlertRequestError(er).response();
                return false;
            });
        },

        async activatePassword(payload) {
            return await axios.post(route.activatePassword(), payload).then((r) => {
                const response = r.data
                this.token = response.data.token
                this.meta = {...response.data.meta}
                return true
            }).catch((er) => {
                appAlertRequestError(er).response();
                if (er.response.data.hasOwnProperty('errors')) {
                    this.errors = er.response.data.errors
                }
                return false;
            });
        },

        //TODO
        changePassword(payload) {
            return axios.post(route.changePassword()).then((r) => {
                return true;
            }).catch((er) => {
                appAlertRequestError(er).response();
                return false;
            })
        },

        getAuthDetails() {
            this.loader = true;
            const authDetails = route.user().authDetails();
            return axios.get(authDetails).then((r) => {
                console.log('getAuthDetails', r.data);
                this.loader = false;
            }).catch((er) => {
                console.log(`Route getAuthDetails: ${userProfile}`);
                this.loader = false;
            });
        },

        getActivityLog() {
            this.loader = true;
            const activityLogs = route.user().activityLogs();
            return axios.get(activityLogs).then((r) => {
                console.log(`Get activity`, r.data);
                this.loader = false;
            }).catch((er) => {
                console.log(`Get er`, er);
                this.loader = false;
            });
        },

        sendOtp(userId) {
            this.loader = true
            const sendOtpRoute = route.sendOtp()
            console.log('ping send otp',userId)
            return axios.post(sendOtpRoute, {...userId}).then(() => {
                this.loader = false;
                this.isOtpSent = true;
        return true;
      }).catch((er) => {
        console.log(`Get er`, er);
        this.loader = false;
      });
    },
    activateViaOtp(payload){
      this.loader=true
      const activateOtp=route.activateOtp()
      return axios.post(activateOtp,{...payload}).then((r)=>{
        const response = r.data;
        this.loader = false;
        this.token=response.data.token
        this.meta=response.data.meta

        return true;
      }).catch((er) => {
        this.loader = false;
        this.otpMessage = er.response.data.data.message
        return false;
      });},

        startTimer(){
            clearInterval(this.interval)
            console.log('ping this timer interval',this.interval)
           this.interval = setInterval(() => {
                this.timerRemaining--;
            },1000);
        },

        stopTimer(){
            console.log('ping this timer stop interval',this.interval)
            clearInterval(this.interval)
        }
    },
    persist: {
        storage: localStorage,
        paths: ['userAuth', 'token', 'meta','timerRemaining'], // state you want to persists
        serializer: {
            deserialize: parse,
            serialize: stringify,
        },
        beforeRestore: (ctx) => {
            console.log(`about to restore '${ctx.store.$id}'`);
        },
        afterRestore: (ctx) => {
            console.log(`just restored '${ctx.store.$id}'`);
        },
    },
});
