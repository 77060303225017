import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  createMemoryHistory, useRouter, useRoute,
} from 'vue-router';
import {authModule} from '@/store/auth.module';
import __ from 'lodash';
import {appComposables} from '@/composables/global.composables';

/**
 * @property template  use to wrap all the child component here.
 *
 */
const wrapper = {
  template: `<router-view/>`,
};

const routes = [
  {
    path: '/',
    name: 'App',
    redirect: '/login',
    component: () => import('@/App.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
      },
      {
        path: 'h',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        redirect: '/h/dashboard',
        meta: {
          auth: true,
          activated: true,
        },
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            //meta : {
            //    auth: true,
            //},
            component: () => import('@/views/pages/dashboard/Dashboard.vue'),
          },
          {
            path: 'master-file',
            name: 'MasterFile',
            component: async () => {
              return wrapper;
            },
            children: [
              {
                path: 'coa',
                name: 'COA',
                component: async () => {return wrapper;},
                redirect: '/index',
                children: [
                  {
                    path: 'index',
                    name: 'ChartOfAccountList',
                    component: () => import('@/views/pages/masterfile/coa/ChartOfAccounts.vue'),
                  },
                  {
                    path: 'create',
                    name: 'CreateAccount',
                    component: () => import('@/views/pages/masterfile/coa/chart/CreateAccount.vue'),
                    props: true,
                  },
                  {
                    path: 'update/:id',
                    name: 'UpdateAccount',
                    component: () => import('@/views/pages/masterfile/coa/chart/UpdateAccount.vue'),
                    props: true,
                  },
                ],
              },
              {
                path: 'payee-payor',
                name: 'PayeePayor',
                component: async () => {
                  return wrapper;
                },
                meta: {
                  requiresAuth: true,
                },
                children: [
                  {
                    path: 'list',
                    name: 'PayeePayorList',
                    component: () => import('@/views/pages/masterfile/pyl/PayeePayorList.vue'),
                  },
                  {
                    path: 'add',
                    name: 'AddPayor',
                    component: () => import('@/views/pages/masterfile/pyl/AddPayor.vue'),
                  },
                  {
                    path: 'update/:id',
                    name: 'UpdatePayor',
                    component: () => import('@/views/pages/masterfile/pyl/UpdatePayor.vue'),
                                        children: [
                                          {
                                            path: 'payor-form',
                                            name: 'PayorForm'
                                          },
                                          {
                                            path: 'contact-directory',
                                            name: 'ContactDirectory'
                                          },
                                          {
                                            path: 'history-logs',
                                            name: 'PayorHistoryLogs'
                                          },
                                          {
                                            path: 'attachment',
                                            name: 'PayorAttachment',
                                          },
                                        ]
                  },
                ],
              },
              {
                path: 'account-files',
                name: 'AccountFiles',
                component: () => import('@/views/pages/masterfile/af/AccountFile.vue'),
              },
              {
                path: 'initial-ending-balance',
                name: 'InitialEndingBalance',
                component: () => import('@/views/pages/masterfile/ieb/InitialEndingBalance.vue'),
              },
            ],
          },

          //           redirect: '/journal',
          //     children: [
          //   {
          //     path: 'journal',
          //     name: 'JournalVoucher',
          //     // component: () => import('@/views/pages/transaction/Transactions.vue'),
          //     component: async () => {return wrapper},
          //     redirect: '/list',
          //     children: [
          //       {
          //         path: 'list',
          //         name: 'JournalVoucherList',
          //         component: () => import('@/views/pages/transaction/journal/JournalVoucher.vue'),
          //       },
          //       {
          //         path: 'add-journal',
          //         name: 'AddJournalVoucher',
          //         component: () => import('@/views/pages/transaction/journal/AddJournalVoucher.vue'),
          //       },
          //     ],
          //   },
          //   {
          //     path: 'check',
          //     name: 'CheckVoucher',
          //     // component: () => import('@/views/pages/transaction/Transactions.vue'),
          //     component: async () => {return wrapper},
          //     redirect: '/list',
          //     children: [
          //       {
          //         path: 'list',
          //         name: 'CheckVoucherList',
          //         component: () => import('@/views/pages/transaction/check/CheckVoucher.vue'),
          //       },
          //       {
          //         path: 'add-check',
          //         name: 'AddCheckVoucher',
          //         component: () => import('@/views/pages/transaction/check/AddCheckVoucher.vue'),
          //       },
          //     ],
          //   },
          //   {
          //     path: 'cash',
          //     name: 'CashReceipt',
          //     // component: () => import('@/views/pages/transaction/Transactions.vue'),
          //     component: () => {return wrapper},
          //     redirect: '/list',
          //     children: [
          //       {
          //         path: 'list',
          //         name: 'CashReceiptList',
          //         component: () => import('@/views/pages/transaction/cash/CashReceipt.vue'),
          //       },
          //       {
          //         path: 'add-cash-receipt',
          //         name: 'AddCashReceipt',
          //         component: () => import('@/views/pages/transaction/cash/AddCashReceipt.vue'),
          //       },
          //     ],
          //   },
          // ],

          {
            path: 'transactions',
            name: 'Transactions',
            component: async () => {
              return wrapper;
            },
            children: [
              {
                name: 'TransactionList',
                path: 'list/:type',
                meta: {
                  auth: true,
                },
                component: () => import('@/views/pages/transaction/TransactionList.vue'),
              },
              {
                name: 'AddTransaction',
                path: 'add/:type',
                meta: {
                  auth: true,
                },
                component: () => import('@/views/pages/transaction/TransactionForm.vue'),
              },
              {
                name: 'UpdateTransaction',
                path: 'view/:type/:id',
                meta: {
                  auth: true,
                },
                component: () => import('@/views/pages/transaction/TransactionForm.vue'),
              },
            ],
          },
          {
            path: 'account-closing',
            name: 'AccountClosing',
            //meta : {
            //    auth: true,
            //},
            component: () => import('@/views/pages/account-closing/AccountClosing.vue'),
          },
          {
            path: 'reports',
            name: 'Reports',
            // component: () => import('@/views/pages/reports/Reports.vue'),
            component: async () => {
              return wrapper;
            },
            redirect: '/journal',
            children: [
              {
                path: 'journal',
                name: 'JournalVoucherReports',
                component: () => import('@/views/pages/reports/_sub/vouchers/JournalVoucher.vue'),
              },
              {
                path: 'cash',
                name: 'CashReceiptReports',
                component: () => import('@/views/pages/reports/_sub/vouchers/CashReceipt.vue'),
                //TODO passing props here
              },
              {
                path: 'check',
                name: 'CheckVoucherReports',
                component: () => import('@/views/pages/reports/_sub/vouchers/CheckVoucher.vue'),
                //TODO passing props here.
              },
              {
                path: 'income-statement',
                name: 'IncomeStatement',
                component: () => import('@/views/pages/reports/_sub/IncomeStatement.vue'),
              },
              {
                path: 'balance-sheet',
                name: 'BalanceSheet',
                component: () => import('@/views/pages/reports/_sub/BalanceSheet.vue'),
              },
              {
                path: 'trial-balance',
                name: 'TrialBalance',
                component: () => import('@/views/pages/reports/_sub/TrialBalance.vue'),
              },
              {
                path: 'ledger',
                name: 'LedgerReports',
                //component: () => import('@/views/pages/reports/_sub/Ledger.vue'),
                component: async () => { return wrapper;},
                children: [
                  {
                    path: 'journal-ledger',
                    name: 'JournalLedger',
                    component: () => import('@/views/pages/reports/_sub/ledgers/JournalLedger.vue'),
                  },
                  {
                    path: 'general-ledger',
                    name: 'GeneralLedger',
                    component: () => import('@/views/pages/reports/_sub/ledgers/GeneralLedgerRev.vue'),
                  },
                  {
                    path: 'subsidiary-ledger',
                    name: 'SubsidiaryLedger',
                    component: () => import('@/views/pages/reports/_sub/ledgers/SubsidiaryLedger.vue'),
                  },
                ],
              },
              {
                path: 'vouchers',
                name: 'VoucherReports',
                component: async () => { return wrapper;},
                children: [
                  {
                    path: 'check',
                    name: 'CheckVoucherReports',
                    component: () => import('@/views/pages/reports/_sub/vouchers/CheckVoucher.vue'),
                  },
                  {
                    path: 'cash',
                    name: 'CashReceiptReports',
                    component: () => import('@/views/pages/reports/_sub/vouchers/CashReceipt.vue'),
                  },
                  {
                    path: 'journal',
                    name: 'JournalVoucherReports',
                    component: () => import('@/views/pages/reports/_sub/vouchers/JournalVoucher.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'company-settings',
            name: 'CompanySettings',
            component: async () => {return wrapper;},
            children: [
              {
                path: 'index',
                name: 'IndexCompanySettings',
                component: () => import ('@/views/pages/company/IndexCompanyDetails.vue'),
              },
            ],
          },

          //todo monitoring routes here.
          {
            path: 'monitoring-activity',
            name: 'MonitoringActivity',
            component: async () => { return wrapper;},
            children: [
              {
                path: 'logs',
                name: 'ActivityLogs',
                component: () => { return wrapper;},
                children: [
                  //TODO logs routes here.
                  {
                    path: 'transaction',
                    name: 'TransactionLogs',
                    component: () => import('@/views/pages/monitoring/MonitoringList.vue'),
                    props: true,
                  },
                  {
                    path: 'login',
                    name: 'LoginLogs',
                    component: () => import('@/views/pages/monitoring/MonitoringList.vue'),
                    props: true,
                  },
                  {
                    path: 'change-passwords',
                    name: 'ChangePasswordLogs',
                    component: () => import('@/views/pages/monitoring/MonitoringList.vue'),
                    props: true,
                  },
                ],
              },
            ],
          },
          {
            path: 'settings',
            name: 'Settings',
            // component: () => import('@/views/pages/Settings.vue'),
            component: wrapper,
            children: [
              {
                path: 'user',
                name: 'SettingUser',
                // component: () => import('@/views/pages/settings/user/SettingUser.vue'),
                component: async () => {
                  return wrapper;
                },
                children: [
                  {
                    path: 'index',
                    name: 'IndexUser',
                    component: () => import('@/views/pages/settings/user/IndexUser.vue'),
                  },
                  {
                    path: 'create',
                    name: 'CreateUser',
                    component: () => import('@/views/pages/settings/user/CreateUser.vue'),
                  },
                  {
                    path: 'view/:hash_id',
                    name: 'ViewUser',
                    component: () => import('@/views/pages/settings/user/ViewUser.vue'),
                  },
                ],
              },
              {
                path: 'fs-type',
                name: 'SettingFsType',
                component: () => import('@/views/pages/settings/fs-types/SettingFsType.vue'),
                children: [
                  {
                    path: 'index',
                    name: 'IndexFsType',
                    component: () => import('@/views/pages/settings/fs-types/IndexFsType.vue'),
                  },
                ],
              },
              {
                path: 'otp-settings',
                name: 'OtpSettings',
                component: () => import('@/views/pages/settings/otp-settings/OtpSettings.vue'),
                children: [
                  {
                    path: 'index',
                    name: 'IndexOtpSettings',
                    component: () => import ('@/views/pages/settings/otp-settings/IndexOtpSettings.vue'),
                  },
                ],
              },
              {
                path: 'draft-settings',
                name: 'DraftSettings',
                component: async () => {return wrapper;},
                children: [
                  {
                    path: 'templates',
                    name: 'DraftTemplateList',
                    component: () => import('@/views/pages/settings/draft-settings/DraftTemplateList.vue'),
                  },
                  {
                    path: ':type',
                    name: 'DraftTemplateFormNew',
                    component: () => import('@/views/pages/settings/draft-settings/DraftTemplateForm.vue'),
                  },
                  {
                    path: ':type/:hashId',
                    name: 'DraftTemplateFormView',
                    component: () => import('@/views/pages/settings/draft-settings/DraftTemplateForm.vue'),
                  },
                ],
              },

              // collection type
              {
                path: 'collection-type',
                name: 'CollectionType',
                component: () => import('@/views/pages/settings/collection-type/IndexCollection.vue'),
              },
                // disbursement type
              {
                path: 'disbursement-type',
                name: 'DisbursementType',
                component: () => import('@/views/pages/settings/disbursement-type/IndexDisbursement.vue'),
              },
              {
                path: 'position',
                name: 'Position',
                component: () => import('@/views/pages/settings/position/IndexPosition.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'activate-password',
        name: 'ActivatePassword',
        meta: {
          requiresAuth: false,
          activated: false,
        },
        component: () => import('@/views/pages/password/ActivatePassword.vue'),
      },
      {
        path: 'challenge-otp',
        name: 'ActivateOtp',
        meta: {
          requiresAuth: false,
          activated: true,
        },
        component: () => import('@/views/pages/otp/ActivateOtp.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/pages/fallback/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  const {token} = authModule();
  const isEmptyToken = __.isEmpty(token);

  const requiresAuth = to.meta.auth;
  const toName = to.name;

  const protectedRoutes = [
    'TransactionList',
    'AddTransaction',
    'UpdateTransaction',
  ];
  const allowedTypes = ['cv', 'cr', 'jv'];
  const jumpRoutes = ['Login', 'App'];

  if (to.matched.some(m => m.meta.auth) && isEmptyToken) {
    next({name: 'Login'});
    return;
  }

  console.log('to.matched.some(m => m.meta.auth)',
      to.matched.some(m => m.meta.auth));
  console.log('isEmptyToken', isEmptyToken);

  if (to.matched.some(m => m.meta.auth) && !isEmptyToken) {
    if (protectedRoutes.includes(toName) &&
        !allowedTypes.includes(to.params.type)) {
      next({name: 'not-found'});
      return;
    }

    if (jumpRoutes.includes(toName)) {
      next({name: 'Home'});
      return; // Important to prevent further execution
    }

    appComposables.hideSidebar();
  }

  next();
});

export default router;
