/**
 krbs_kras_fe
 12/11/23,12:16 pm
 */
'use strict';
export const route = {
  login: () => 'login',
  logout: () => 'logout',
  activateOtp:()=>'activate-otp',
  sendOtp:()=>'send-otp',
  activatePassword: () => 'activate-password',
  changePassword: () => 'change-password',
  user: () => {
    const prefix = 'user';
    return {
      prefix: () => `${prefix}`,
      authDetails: () => `${prefix}/auth-details`,
      activityLogs: () => `${prefix}/activity-logs`,
      uploadAvatar: (payload) => `${prefix}/upload-avatar/${payload}`,
      setAvatar: (payload) => `${prefix}/set-avatar/${payload}`,
      deleteAvatar: (payload) => `${prefix}/delete-avatar/${payload}`
    };
  },
  otp: () => {
    const prefix = 'otp';
    return {
      prefix: () => `${prefix}`,
      getEmailList: () => `${prefix}/email/list`,
      deleteEmail: (payload) => `${prefix}/email/delete/${payload}`,
      createEmail: () => `${prefix}/email/add`,
      updateEmail: (payload) => `${prefix}/email/update/${payload}`,
      getIpList: () => `${prefix}/ip/list`,
      deleteIp: (payload) => `${prefix}/ip/delete/${payload}`,
      createIp: () => `${prefix}/ip/add`,
      updateIp: (payload) => `${prefix}/ip/update/${payload}`
    }
  },
  voucher: () => 'voucher',
  fsType: () => 'fs-type',
  category: () => 'category',
  position: () => 'position',
  account: () => 'account',
  accountType: () => 'account-type',
  transaction: () => 'transaction',
  entry: () => 'entry',
  payor: () => {
    const prefix = 'payor';
    return {
      pref : () => prefix,
      historyLogs : (id) => `${prefix}/${id}/history-logs`,
      attachments : (id) => `${prefix}/attachment/${id}`,
    }
  },
  contact_directory: () => 'contact-directory',
  province: () => 'province',
  region: () => 'region',
  barangay: () => 'barangay',
  city: () => 'city',
  commonKeys: () => 'get-common-keys',
  getKeyResource : () => `getKeyResource`,
  coa: () => {
    const prefix = 'coa';
    return {
      prefix:()=>prefix,
      list: () => `${prefix}/list`,
      create: () => `${prefix}/create`,
      update: () => `${prefix}/update`,
      referenceCode: () => `${prefix}/referenceCode`,
      getListByCategory: (payload) => `${prefix}/by-category/${payload}`,
      getListByAccountRef: (payload) => `${prefix}/by-account-ref/${payload}`,
      getParentAccount: () => `${prefix}/parent-account`,
      getCashAccount: () => `${prefix}/cash-account`,
      //TODO preview Coa
      preview : () => `krbs/${prefix}/preview-chart-of-accounts`,
    };
  },
  accountCategory: () => {
    const prefix = 'account-category';
    return {
      pref : () => prefix,
      list: () => `${prefix}`,
      create: () => `${prefix}`,
      show: (payload) => `${prefix}/${payload}`,
      update: (payload) => `${prefix}/${payload}`,
    };
  },

  transactionEntry: () => {
    const prefix = 'transaction-entry';
    const attachmentPrefix = 'attachment'
    const remarksPrefix = 'transaction-remarks'
    return {
      pref : () => prefix,
      list : () => `${prefix}/list`,
      init : () => `${prefix}/init`,
      //create : () => `${prefix}/create`,
      show : (transactionId) => `${prefix}/show/${transactionId}`,
      update : (transactionId) => `${prefix}/update/${transactionId}`, //acting as save
      drafting:(transactionId) => `${prefix}/drafting/${transactionId}`,
      preparing: (transactionId) => `${prefix}/preparing/${transactionId}`,
      posting : (transactionId) => `${prefix}/posting/${transactionId}`,
      disregards: (transactionId) => `${prefix}/disregards/${transactionId}`,
      postingMultiple : (transactionId) => `${prefix}/postingMultiple/${transactionId}`,
      preview : ()=>"krbs/preview-transaction",
      getFiles: () => `${prefix}/${attachmentPrefix}/get`,
      uploadFiles: (transactionId) => `${prefix}/${attachmentPrefix}/store/${transactionId}`,
      deleteFile: (transactionId) => `${prefix}/${attachmentPrefix}/destroy/${transactionId}`,
      generateDisbursementNo: (disbursementId, transactionHashId) => `${prefix}/generate-check-no/${disbursementId}/${transactionHashId}`,

      getTransactionRemarks: () => `${remarksPrefix}`,
      postTransactionRemarks: () => `${remarksPrefix}`,
      getRemarksByTransaction: (transactionId) => `${remarksPrefix}/get-transaction-remarks/${transactionId}`,
      showTransactionRemark: (transactionRemarkId) => `${remarksPrefix}/${transactionRemarkId}`,
      updateTransactionRemark: (transactionRemarkId) => `${remarksPrefix}/${transactionRemarkId}`,
      deleteTransactionRemark: (transactionRemarkId) => `${remarksPrefix}/${transactionRemarkId}`,
    }
  },
  draftTemplates : () => {
    const prefix = 'draft';
    return {
      prefix  : () => `${prefix}`,
      getTemplates: () => `${prefix}/getDraftTemplates`,
      getTemplate : (id) => `${prefix}/getDraftTemplate/${id}`,
      createTemplate: () => `${prefix}/createDraftTemplate`,
      updateTemplate: (id) => `${prefix}/updateDraftTemplate/${id}`,
      deleteTemplate: (id) => `${prefix}/deleteDraftTemplate/${id}`,

      getEntryTemplates: () => `${prefix}/getDraftEntryTemplate`,
      getEntryTemplate: (id) => `${prefix}/getDraftEntryTemplate/${id}`,
      createEntryTemplate: () => `${prefix}/createDraftEntryTemplate`,
      updateEntryTemplate: (id) => `${prefix}/updateDraftEntryTemplate/${id}`,
      deleteEntryTemplate: (id) => `${prefix}/deleteDraftEntryTemplate/${id}`,
    }
  },
  setting : () => {
    const prefix = 'setting'
    return {
      collectionType : () => `${prefix}/collection-type`,
      position : () => `${prefix}/position`,
      cashAccount : () => `${prefix}/cash-account`,
      disbursementType: () => `${prefix}/disbursement-type`
      //todo moved settings other routes related on settings here .
    }
  },
  report : () => {
    const prefix = 'report';
    const previewPrefix = 'krbs'
    return {
      generalLedger : `${prefix}/general-ledger`,
      subsidiaryLedger : `${prefix}/subsidiary-ledger`,
      trialBalance : `${prefix}/trial-balance`,
      journalLedger : `${prefix}/journal-ledger`,
      checkVoucher : `${prefix}/check-voucher`,
      cashReceipt : `${prefix}/cash-receipt`,
      journalVoucher : `${prefix}/journal-voucher`,
      ledgerTotal: `${prefix}/ledger-total`,
      tbTotal: `${prefix}/trial-balance-total`,
      incomeStatement: `${prefix}/income-statement`,
      balanceSheet: `${prefix}/balance-sheet`,
      /** PDF report-related routes **/
      previewLedger: (ledgerType) => `${previewPrefix}/report/preview-${ledgerType}-ledger`,
      previewVoucher : () => `${previewPrefix}/${prefix}/preview-voucher`,
      previewTrialBalance:`${previewPrefix}/${prefix}/preview-trial-balance`,
      previewIncomeStatement:`${previewPrefix}/${prefix}/preview-income-statement`,
      previewBalanceSheet:`${previewPrefix}/${prefix}/preview-balance-sheet`,
      /** End of PDF report-related routes**/
      viewTransactionByRefCode : (refCode) => `${prefix}/view-transaction/${refCode}`,

    }
  },
  dashboard: () => {
    const prefix = "dashboard";
    return {
      transactionSummary: `${prefix}/transaction-summary`,
      incomeStatement: `${prefix}/income-statement`
    }
  }
};
export default {
  route,
};
