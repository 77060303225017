<template>
    <nav class="app-topnav navbar navbar-expand navbar-light bg-light">
        <!-- Sidebar Toggle-->
        <button class="btn btn-link ms-1" id="sidebarToggleBtn" href="#!" @click="handleSidebarToggle">
            <font-awesome-icon class="fa-xl" icon="bars"/>
        </button>
        <!--  Left Links -->
        <ul class="navbar-nav ms-2 text-nowrap" style="cursor: pointer;">
            <li class="app-header-link" @click="newTransaction('cv')"
                :class="{'header-active-parent-link' : appComposables.isRouteActive('AddTransaction') && useRoute().params.type === 'cv'}">
                <font-awesome-icon icon="plus"/>
                CV
            </li>

            <li class="app-header-link" @click="newTransaction('cr')"
                :class="{'header-active-parent-link' : appComposables.isRouteActive('AddTransaction') && useRoute().params.type === 'cr'}">
                <font-awesome-icon icon="plus"/>
                CR
            </li>
            <li class="app-header-link" @click="newTransaction('jv')"
                :class="{'header-active-parent-link' : appComposables.isRouteActive('AddTransaction') && useRoute().params.type === 'jv'}">
                <font-awesome-icon icon="plus"/>
                JV
            </li>
        </ul>
        <!-- Right Links -->
      <ul class="navbar-nav ms-auto me-0 me-md-2 align-items-center">
        <li class="nav-item">
            <notifications-dropdown />
        </li>

        <li class="nav-item">
          <a class="">
            <img :src="userAuth.link || avatar1" alt="Avatar" class="app-header-avatar">
          </a>
        </li>
<!--        <ul class="navbar-nav ms-auto me-0 me-md-2 align-items-center">-->
<!--            <li class="nav-item">-->
<!--                <a class="nav-link mt-1" href="#">-->
<!--                    <font-awesome-icon class="fa-2x" icon="bell" style="height: 24px;"/>-->
<!--                </a>-->
<!--            </li>-->

<!--            <li class="nav-item">-->
<!--                <a class="nav-link">-->
<!--                    <img :src="userAuth.link || avatar1" alt="Avatar" class="avatar">-->
<!--                </a>-->
<!--            </li>-->

      <!--  -->
      <li class="nav-item dropdown">
        <a style="font-size: 16px !important;" class="nav-link avatar-link align-middle d-flex align-items-center gap-2 transition pe-md-0"
           id="navbarDropdown" href="#" role="button"
           data-bs-toggle="dropdown" aria-expanded="false">

                    <div class="lh-sm w-100 text-end">
                        <small class="fw-bold d-block p-0 text-nowrap">{{
                                [
                                    appComposables.ucFirst(userAuth.last_name),
                                    ', ',
                                    appComposables.ucFirst(userAuth.first_name),
                                    ' ',
                                    appComposables.ucFirst(userAuth.middle_name)?.substring(0, 1) ?? '',
                                ].join('')
                            }} </small>
                        <!--<small class="text-secondary text-nowrap">-->
                        <!--System Admin-->
                        <!--</small>-->
                    </div>
                    <font-awesome-icon icon="angle-down"/>
                </a>
                <ul class="dropdown-menu dropdown-menu-end" style="font-size: 14px !important;"
                    aria-labelledby="navbarDropdown">
                    <li>
                        <a class="dropdown-item">Profile</a>
                    </li>
                    <li>
                        <a class="dropdown-item">Activity Log</a>
                    </li>
                    <li>
                        <hr class="dropdown-divider"/>
                    </li>
                    <li>
                        <a class="dropdown-item" @click="formMethods.logout">Logout</a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>
<script>
import {computed, nextTick, onMounted, onUnmounted, ref, watchEffect, watch} from 'vue';
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {authModule} from '@/store/auth.module';
import __ from 'lodash';
import {appComposables} from '@/composables/global.composables';
import {userModule} from '@/store/user.module';
import {storeToRefs} from 'pinia';
import {useUserComposable} from '@/composables/user.composable';
import avatar1 from '@/assets/avatars/avatar1.jpg';
import {route} from '@/services/uri.service';
import {sidebarModule} from "@/store/sidebar.module";
import {applicationAlert} from "@/composables/application.alerts";
import NotificationsDropdown from "@/components/BaseNotifications/NotificationsDropdown.vue";

export default {
  name: 'AppHeader',
  computed: {
    route() {
      return route;
    },
  },
  methods: {useRoute},
  components: {NotificationsDropdown, FontAwesomeIcon},
  emits: [
    'toggleSidebar',
  ],
  setup(props, {emit}) {
    const useSidebarModule = sidebarModule();
    const router = useRouter();
    const userAuthModule = authModule();
    const {getUserAuth: userAuth} = authModule();
    const useUserModule = userModule();
    const userHashId = ref('');
    const selectedAvatar = ref(null);
    const avatar = computed(() => selectedAvatar.value ? selectedAvatar.value : avatar1);
    const {
      getResource,
    } = storeToRefs(useUserModule);

    const {
      user,
      getUser,
    } = useUserComposable();
    const formMethods = {
      userProfile() {
        nextTick(() => {
          console.log('getting user profile');
        });
      },
      activityLog() {
        nextTick(() => {
          console.log('getting user activity log');
        });
      },

      async logout() {
        if (await applicationAlert().question('Logout', 'Are you sure you want to logout?')) {
          userAuthModule.logout().then((r) => {
            if (r ===true) {
              nextTick(() => {
                window.localStorage.clear();
                if (__.isEmpty(localStorage)) {
                  applicationAlert().success('Logout', 'You have logged out successfully.');
                  router.push(
                      {
                        name: 'Login',
                      },
                  );
                }
              });
            }
          });
        }
      },
    };

    function newTransaction(type) {
      localStorage.removeItem('trn-hash-id');
      router.push({
        name: 'AddTransaction',
        query: {compose: ''},
        params: {type: type},
      });
      //.then(() => window.location.reload())

      //add .router-link-active to li.nav-link that is clicked
    }

    function handleSidebarToggle() {
      useSidebarModule.toggleSidebarByClick();
    }

    onMounted(async () => {
      userHashId.value = userAuth.hash_id;

      let response = await getUser(userHashId.value);
      if (response) {
        let hash_id = user?.active_profile?.file_hash_id;
        let latestImg = user?.files?.find(file => file.hash_id === hash_id);
        if (userAuth.hash_id === userHashId.value) {
          userAuthModule.$patch((state) => {
            state.userAuth.link = latestImg?.link_viewable;
          });
        }
      }
    });

    watch(useRoute, ([newValue, oldValue]) => {
      console.log('current route', useRoute());
    });
    onMounted(() => {
      console.log('current route', useRoute());
    });

    watchEffect(() => {
      let files = getResource.value?.files;
      let active_file_hash_id = getResource.value?.active_profile?.file_hash_id;

      let latestImg = Array.isArray(files) && files.length > 0
          ? files.find(file => file?.hash_id === active_file_hash_id)
          : null;

      selectedAvatar.value = latestImg?.link_viewable || avatar1;
    });

    onUnmounted(() => {
      userAuthModule.$reset();
    });

    return {
      formMethods,
      appComposables,
      userAuth,
      newTransaction,
      avatar,
      avatar1,
      handleSidebarToggle,
      useSidebarModule
    };
  },
};
</script>

<style scoped>

</style>
