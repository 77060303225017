import AppHeader from '@/views/layout/AppHeader.vue';
import AppFooter from '@/views/layout/AppFooter.vue';
import AppSideNavLink from '@/views/layout/AppSideNavLink.vue'
import AppMain from '@/views/layout/AppMain.vue'
import CoaTableContent from '@/views/pages/masterfile/coa/chart/CoaTableContent.vue'


export const customComponents = {
   config(app){
     app.component('app-header',AppHeader)
     app.component('app-side-nav-link',AppSideNavLink)
     app.component('app-main',AppMain)
     app.component('app-footer',AppFooter)
     app.component('coa-table-content',CoaTableContent)
  }
}
export default {
  customComponents
}
