/**
 krbs_kras_fe
 6/6/24,11:23 am
 */
'use strict';
import {result} from 'lodash';
import {appAlert} from "@/composables/alert.composables";
import Swal from "sweetalert2";

export const applicationAlert = () => {
  //content
  const alertContent = (title = 'foo title', message = 'foo message') => {
    let msgHtml = 'test';
    console.log('sample', title);
    if (__.isArray(message)) {
      Object.keys(message).forEach((key, index) => {
        msgHtml += `<p class="text-start">${index + 1}. ${message[key]}</p>`;
      });
    } else {
      msgHtml = `<p>${message}</p>`;
    }
    return `<div class="modal-dialog">
              <div class="modal-content text-center">
                <div class="modal-header mb-3">
                  <h2 class="modal-title mx-auto">${title}</h2>
                </div>
                <div class="modal-body">
                  ${msgHtml}
                </div>
              </div>
            </div>`;
  };

  //animation style
  const animationStyle = {
    showClass: {
      popup: `animate__animated animate__fadeInUp animate__faster`,
    },
    hideClass: {
      popup: `animate__animated animate__fadeOutDown animate__faster`,
    },
    allowEscapeKey: false,
    allowOutsideClick: false,
    buttonsStyling: false,
  };

  /**
   * return default application alerts. . . .
   */
  return {
    //error
    error: (title, message) => {
      return Swal.fire({
        icon: 'error',
        html: `${alertContent(title, message)}`,
        customClass: {
          confirmButton: 'btn swal-btn-danger',
        },
        ...animationStyle,
      });
    },

    question: async (title, message, confirmBtnText = 'Yes',
                     cancelBtnText = 'No', icon = 'question') => {
      return await Swal.fire({
        html: `${alertContent(title, message)}`,
        icon: `${icon}`,
        showCancelButton: true,
        confirmButtonText: `${confirmBtnText}`,
        cancelButtonText: `${cancelBtnText}`,
        customClass: {
          confirmButton: `btn me-2 ${icon} === 'question' ? swal-btn-primary : swal-btn-${icon}`,
          cancelButton: 'btn swal-btn-danger',
        },
        ...animationStyle,
      }).then((result) => {
        return result.value ? result.value : false;
      });
    },

    warning: (title = 'foo title', message = 'foo message', html = '') => {
      Swal.fire({
        title: `${title}`,
        icon: 'warning',
        text: `${message}`,
        html: `${html}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: 'btn swal-btn-warning',
        },
        buttonsStyling: false,
      }).then((r) => {
        return r.value;
      });
    },

    //info
    info: (title, message) => {
      return Swal.fire({
        icon: 'info',
        html: `${alertContent(title, message)}`,
        buttonsStyling: false,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          confirmButton: 'btn swal-btn-info',
        },
        ...animationStyle,
      });
    },

    //success,
    success: (title, message) => {
      return Swal.fire({
        icon: 'success',
        html: `${alertContent(title, message)}`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn swal-btn-success',
        },
        ...animationStyle,
      });
    },

    //confirm
    confirm: async (title, message, confirmBtnText = 'Yes',
                    cancelBtnText = 'No') => {
      return Swal.fire({
        icon: 'question',
        html: `${alertContent(title, message)}`,
        showCancelButton: true,
        confirmButtonText: `${confirmBtnText}`,
        cancelButtonText: `${cancelBtnText}`,
        customClass: {
          confirmButton: 'btn swal-btn-success me-2',
          cancelButton: 'btn swal-btn-secondary',
        },
        ...animationStyle,
      }).then((result) => {
        return result.value ? result.value : false;
      });
    },
  };
};

export default {
  applicationAlert
};
