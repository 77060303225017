<template>
  <!--  <router-link-->
  <!--      :to="`/notifications/${notification.id}`"-->
  <!--      class="text-reset"-->
  <!--  >-->
  <div class="border rounded m-3 d-flex gap-2 p-3">
    <div style="height: 36px; min-width: 36px;"
         :class="notification.is_read ? 'bg-secondary' : 'bg-krbs-primary' "
         class="rounded-circle d-flex justify-content-center align-items-center">
      <font-awesome-icon id="notificationDropdown"
                         aria-expanded="false"
                         data-bs-toggle="dropdown"
                         class="dropdown-toggle text-white"
                         type="button"
                         icon="bell"
                         style="height: 18px;"/>
    </div>
    <div style="font-size: 14px">
      <p v-if="notification.user_id" class="mb-0"><span class="fw-bold">{{ notification.user_id }}</span> has logged into the system.</p>
      <p v-else class="mb-0"><span class="fw-bold">{{ notification.transaction_id }}</span> has been added.</p>

      <small>{{ moment(notification.created_at).format('MM/DD/YYYY, hh:mm:s a') }}</small>
    </div>
  </div>
  <!--  </router-link>-->
</template>
<script>

import {defineProps, computed} from 'vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import moment from "moment";

// import {notificationsModule} from '@/store/notifications.module';

export default {
  name: 'NotificationPaneItem',
  components: {FontAwesomeIcon},
  props: {
    notification: {
      type: Object,
      required: true,
    },
    useCase: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      moment
    }
  }
}
</script>
<style scoped>
.dropdown-menu-lg {
  width: 320px;
}
</style>
