/**
 krbs_kras_fe
 11/24/23,10:59 am
 */
import {urlRequest} from '@/services/resource.service';
import {ref} from 'vue';
import axios from 'axios';
import {appAlertRequestError} from '@/composables/alert.composables';

export default class ResourceApi {
  constructor(resourceName) {
    this._persist = [
      'resourceList', 'resourceMeta',
    ];
    this._state = {
      resourceList: [],
      resource: {},
      newResource: {},
      resourceMeta: [], // pagination: [],
      resourceLoading: false,
      counter: 0, // sample reference
      errors: ref({}),
      resourceParams: {},
      paginated: {
        last_page: 1,
        first_page: 1,
        current_page: 1,
        start_page: 1,
        per_page: 1,
        from: 0,
        to: 0,
        total: 0,
      },
      loading:true,
    };

    this._getters = {
      getResourceList: (state) => state.resourceList,
      getResource: (state) => state.resource,
      getNewResource: (state) => state.newResource,
      getResourceMeta: (state) => state.resourceMeta,
      getResourceLoading: (state) => state.resourceLoading,
      getErrors: (state) => state.errors,
      getPaginated: (state) => state.paginated,
      getResourceParams: (state) => state.resourceParams,
    };

    this._actions = {
      /**
       * @param payload
       * @param config
       */
      async getAllRecords(payload = {}, config = {}) {
        this.resourceLoading = true;
        if(this.resourceParams) {
          config = {
            params: {
              resourceParams: this.resourceParams
            }
          }
        }
        const urlEncoded = urlRequest(resourceName).encoded(this.resourceParams);
        const isPaginated = Object.keys(this.resourceParams).includes('page') ||  Object.keys(this.resourceParams).includes('perPage');
        return await axios.get(urlEncoded, config).then((r) => {
          const responseCallback = r.data;
          this.resourceList = isPaginated
              ? responseCallback?.data?.data ?? []
              : responseCallback?.data ?? [];
          this.paginated = {
            first_page: 1,
            start_from: responseCallback?.data?.from ?? 1,
            current_page: responseCallback.data.current_page ?? 1,
            per_page: responseCallback.data.per_page ?? 1,
            last_page: responseCallback.data.last_page ?? 1,
            from: responseCallback.data.from ?? 0,
            to: responseCallback.data.to ?? 0,
            total: responseCallback.data.total ?? 0,
          };
          this.resourceMeta = responseCallback.meta;
          this.resourceLoading = false;
          return true;
        }).catch((er) => {
          appAlertRequestError(er).response();
          this.errors = er.response?.data?.errors ?? [];
          this.resourceLoading = false;
          return false;
        });
      },

      /**
       *@param payload
       */
      async getRecord(payload) {
        this.resourceLoading = true;
        try {
          const r =  await axios.get(`${resourceName}/${payload}`)
          const responseCallBack = r.data;
          this.resource = responseCallBack.data;
          this.resourceMeta = responseCallBack.meta;
          this.resourceLoading = false;
          return true;
        }
        catch (er) {
          appAlertRequestError(er).response();
          this.errors = er.response?.data?.errors ?? [];
          this.resourceLoading = false;
          return false;
        }

        // return await axios.get(`${resourceName}/${payload}`).then((r) => {
        //   return true;
        // }).catch((er) => {
        //   appAlertRequestError(er).response();
        //   this.errors = er.response?.data?.errors ?? [];
        //   this.resourceLoading = false;
        //   return false;
        // });
      },

      /**
       * @param payload
       */
      async createRecord(payload,push=false) {
        this.resourceLoading = true;
        const fields = payload ?? this.newResource ?? null;
        return await axios.post(`${resourceName}`, fields).then((r) => {
          const responseCallBack = r?.data;
          this.resource = responseCallBack.data;

          //adding option to maximize resource state management
          if (push === true){
            this.resourceList.push(responseCallBack?.data);
          }
          this.resourceMeta = responseCallBack?.meta ?? null;
          this.newResource = {};
          this.resourceLoading = false;
          this.errors = {};
          return true;
        }).catch((er) => {
          appAlertRequestError(er).response();
          this.errors = er.response?.data?.errors ?? [];
          this.resourceLoading = false;
          return false;
        });
      },

      /**
       * @param id
       * @param payload
       */
      async updateRecord(id = null, payload = null) {
        this.resourceLoading = true;
        if (id === null) {
          id = this.resource?.hash_id ?? payload?.hash_id ?? null;
        }
        if (payload === null) {
          payload = Object.assign({}, this.resource);
        }
        try {
          let res;
          if (payload instanceof FormData) {
            res = await axios.post(`${resourceName}/${id}?_method=PATCH`, payload);
          } else {
            res = await axios.patch(`${resourceName}/${id}`, payload);
          }
          const responseCallBack = res.data;
          if (payload !== null & this.resourceList.length > 0) {
            let resource = this.resourceList.find((val) => {
              return val.hash_id === id;
            });
            Object.assign(resource, payload);
          }

          //this.resource = responseCallBack.data;
          this.resourceMeta = responseCallBack.meta;
          this.errors = {};
          this.resourceLoading = false;
          return true;
        } catch (er) {
          console.log('ping error', er);
          appAlertRequestError(er).response();
          this.errors = er.response?.data?.errors ?? [];
          this.resourceLoading = false;
          return false;
        }
      },

      /**
       * @param id
       */
      async deleteRecord(id) {
        this.resourceLoading = true;
        return await axios.delete(`${resourceName}/${id}`).then((r) => {
          const responseCallBack = r.data;
          let indexResource = this.resourceList.findIndex((val, index) => {
            return val.hash_id === id;
          });
          this.resourceList.splice(indexResource, 1);
          this.resourceMeta = responseCallBack.meta;
          this.resourceLoading = false;
          return true;
        }).catch((er) => {
          appAlertRequestError(er).response();
          this.syncErrors(er)
          this.resourceLoading = false;
          return false;
        });
      },

      clearErrors() {
        this.errors = [];
      },

      syncErrors(er) {
        this.errors = er.response?.data?.errors ?? [];
      },
    };
  }

  get state() {
    return this._state;
  }

  get actions() {
    return this._actions;
  }

  get getters() {
    return this._getters;
  }

  get persist() {
    return this._persist;
  }
}
