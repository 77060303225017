<template>
  <router-view/>
</template>
<style scoped>
</style>
<script>
import {authModule} from '@/store/auth.module';
import {storeToRefs} from 'pinia';
import {useRouter} from 'vue-router';
import {useNetWorkChecker} from '@/composables/network.composable';
export default {
  name: 'App',
  setup(){
    const {initEffect,onLine,message} = useNetWorkChecker();
    initEffect()
    return {
      onLine,
      message
    }
  }
}
</script>
