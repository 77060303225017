import {ref, watchEffect, onMounted, onUnmounted} from 'vue';

export const useNetWorkChecker = () => {
  const message = ref('Welcome to the Network !');
  const onLine = ref(navigator.onLine);
  const showBackOnline = ref(true);

  const doSomething = () => {
    // Functions go here
  };

  const updateOnlineStatus = (e) => {
    const {type} = e;
    onLine.value = type === 'online';
  };

  function initEffect() {
    watchEffect(() => {
      if (onLine.value) {
        showBackOnline.value = true;
        setTimeout(() => {
          showBackOnline.value = false;
        }, 1000);
      }else {
        message.value = 'Your current network is offline.';
      }
    });
  }

  onMounted(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  });

  onUnmounted(() => {
    window.removeEventListener('online', updateOnlineStatus);
    window.removeEventListener('offline', updateOnlineStatus);
  });

  return {
    message,
    onLine,
    showBackOnline,
    doSomething,
    initEffect,
  };
};
