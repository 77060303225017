/**
 elite-verna
 10/10/24,10:04 PM
 */

'use strict'
import {defineStore} from 'pinia'
import {parse, stringify} from 'zipson/lib'
import ResourceApi from '@/services/resource'
import {route} from '@/services/uri.service'
import axios from "axios";
import {shallowRef} from "vue";

let notificationsResource= new ResourceApi()
export const notificationsModule = defineStore('notificationsModule', {
    state: () => ({
        ...notificationsResource.state,
        usersNotifications: [
            {
                hash_id: 1,
                user_id: 'Manzano, Vernalyn L.',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 2,
                user_id: 'Llgas, Mandy S.',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 3,
                user_id: 'Daclizon, Jovanie',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 4,
                user_id: 'Bagus, Nicole',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 5,
                user_id: 'Vito, Christian',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 6,
                user_id: 'Largo, Danielle Joie',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 7,
                user_id: 'Vito, John Larry',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 8,
                user_id: 'Noriega, Darwin',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            }
        ],
        transactionsNotifications: [
            {
                hash_id: 1,
                transaction_id: 'CV22342342300',
                is_read: 0,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 2,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 3,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 4,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 5,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 6,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 7,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 8,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 9,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 10,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 11,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 12,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 13,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },
            {
                hash_id: 14,
                transaction_id: 'CV22342342300',
                is_read: 1,
                created_at: '2024-10-21 07:01:03'
            },

        ]
    }),
    getters: {
        ...notificationsResource.getters,
    },
    actions: {
        ...notificationsResource.actions,
        // getData() {
        //     this.loader = true;
        //     return axios.get(route.notifications()).then((r) => {
        //         this.transactionsCount = r.data.data.transactions_count;
        //     }).catch((error) => {
        //         console.error('Error fetching data:', error);
        //     }).finally(() => {
        //         this.loader = false;
        //     });
        // },
    },
    persist: {
        debug: false,
        storage: localStorage,
        paths: [
            ...notificationsResource.persist,
        ],
        serializer: {
            deserialize: parse,
            serialize: stringify,
        },
        beforeRestore: (ctx) => {
            console.log(`about to restore '${ctx.store.$id}'`)
        },
        afterRestore: (ctx) => {
            console.log(`just restored '${ctx.store.$id}'`)
        },
    },
})
export default {notificationsModule}
