/**
 elite-jov
 11/13/23,10:08 am
 */
'use strict'
import { defineStore } from 'pinia'
import { parse, stringify } from 'zipson/lib'
import ResourceApi from '@/services/resource'
import { route } from '@/services/uri.service'
let userResource = new ResourceApi(route.user().prefix())
export const userModule = defineStore('userModule', {
  state: () => ({
    ...userResource.state,
    name : 'Users'
  }),
  getters: {
    ...userResource.getters,
    //other module state
    getName : state => (state.name)
  },
  actions: {
    ...userResource.actions,
  },
  persist: {
    debug : false,
    storage: localStorage,
    paths: [
      ...userResource.persist,
    ],
    serializer: {
      deserialize: parse,
      serialize: stringify,
    },
    beforeRestore: (ctx) => {
      console.log(`about to restore '${ctx.store.$id}'`)
    },
    afterRestore: (ctx) => {
      console.log(`just restored '${ctx.store.$id}'`)
    },
  },
})
export default { userModule }




