<template>
  <div class="dropdown position-relative me-4 pe-2">
    <font-awesome-icon id="notificationDropdown"
                       aria-expanded="false"
                       data-bs-toggle="dropdown"
                       class="fa-2x dropdown-toggle"
                       type="button"
                       icon="bell"
                       style="height: 24px;"/>
    <span class="badge bg-danger rounded-pill position-absolute pb-0" style="padding-left: 5px; padding-right: 5px;">
        {{
        useNotificationsModule.usersNotifications.filter(notification => notification.is_read === 0).length
        +
        useNotificationsModule.transactionsNotifications.filter(notification => notification.is_read === 0).length
      }}
    </span>

    <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="notificationDropdown">
      <div class="p-3" style="width: 400px">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="m-0">Notifications</h6>
<!--          <a href="/notifications" class="small">View All</a>-->
        </div>
      </div>

      <ul class="nav nav-pills px-3 pb-3">
        <li class="nav-item">
          <a
              class="nav-link"
              :class="{ 'active bg-krbs-primary text-white': activeNotificationsTab === 'transactions' }"
              @click.stop="switchNotificationsTab('transactions')"
          >
            Transactions
          </a>
        </li>
        <li class="nav-item">
          <a
              class="nav-link"
              :class="{ 'active bg-krbs-primary text-white': activeNotificationsTab === 'users' }"
              @click.stop="switchNotificationsTab('users')"
          >
            Users
          </a>
        </li>
      </ul>

      <div
          class="scrollable-notifications-container"
          style="max-height: 300px; overflow-y: auto"
      >
        <template v-if="activeNotificationsTab === 'transactions'">
          <NotificationPaneItem
              v-for="notification in useNotificationsModule.transactionsNotifications.slice(0, itemsToShow)"
              :key="notification.id"
              :notification="notification"
              useCase="pane"
          />
        </template>

        <template v-if="activeNotificationsTab === 'users'">
          <NotificationPaneItem
              v-for="notification in useNotificationsModule.usersNotifications.slice(0, itemsToShow)"
              :key="notification.id"
              :notification="notification"
              useCase="pane"
          />
        </template>
      </div>
    </ul>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { notificationsModule } from "@/store/notifications.module";
import {nextTick, onMounted, ref} from "vue";
import NotificationPaneItem from "@/components/BaseNotifications/NotificationPaneitem.vue";

export default {
  name: 'NotificationsDropdown',
  components: {NotificationPaneItem, FontAwesomeIcon },
  setup() {
    const useNotificationsModule = notificationsModule();
    const activeNotificationsTab = ref('transactions');
    const itemsToShow = ref(10)
    const loadMoreItems = () => {
      itemsToShow.value += 10
    }

    const handleScroll = event => {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        loadMoreItems()
      }
    }

    const resetScrollPosition = () => {
      nextTick(() => {
        const scrollableElement = document.querySelector(
            '.scrollable-notifications-container'
        )
        if (scrollableElement) {
          scrollableElement.scrollTop = 0
        }
      })
    }

    const switchNotificationsTab = tab => {
      activeNotificationsTab.value = tab
      resetScrollPosition()
    }

    onMounted(async () => {
      const scrollableElement = document.querySelector(
          '.scrollable-notifications-container'
      )
      if (scrollableElement) {
        scrollableElement.addEventListener('scroll', handleScroll)
      }
      //  await useNotificationsModule.getNotifications()
    })

    return {
      useNotificationsModule,
      activeNotificationsTab,
      itemsToShow,
      switchNotificationsTab,
      loadMoreItems,
      handleScroll,
      resetScrollPosition
    };
  }
}
</script>
<style scoped>
a.nav-link {
  text-transform: capitalize;
  cursor: pointer;
}
a.nav-link.active {
  font-weight: 600;
}
a.nav-link.active:hover {
  color: white !important;
}

@media screen and (max-width: 768px)  {
  .dropdown-menu {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 70vw;
  }
}

</style>
