/**
 krbs_kras_fe
 11/20/23,1:43 pm
 */
'use strict'
import VueAxios from 'vue-axios'
import axios from 'axios'
import { authModule } from '@/store/auth.module'
import Swal from 'sweetalert2';
import router from '../router/index'
export const axiosConfig = {
  config (app) {
    app.use(VueAxios, axios)
    axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL
    // axios.defaults.timeout = 20000
    axios.interceptors.request.use(
      (config) => {
        const useAuthModule = authModule()
        let token = useAuthModule.getToken
        let searchValue = config.url.match(/\[value\]=(\w+)/)?.[1] || '';

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
          config.headers.Accept = 'application/json'
        }

          if (!config.params?.resourceParams?.preventLoading || !searchValue) {
              Swal.fire({
                  position: "top-right",
                  title: 'Loading...',
                  showConfirmButton: false,
                  toast: true,
              })
          }
        delete config.params?.resourceParams;
        return config
      },
      (error) => {
        Swal.close();
        const sanitizedError = {
          message: error.message,
          name: error.name,
          stack: error.stack,
          method: error.config.method,
          url: error.config.url,
          status: error.stats,
        }
        return Promise.reject(sanitizedError)
      },
    )
    axios.interceptors.response.use((response) => {
      Swal.close();
      return Promise.resolve(response)
    }, (error) => {
      Swal.close();

      if (axios.isCancel(error)) {
        console.warn('Request canceled:',
          `${error?.config?.baseURL}${error?.config?.url} ${error.message}`)
      }
      const config = error.config
      if(error.response.status === 401 && !config._retry) {
        config._retry=true;
        localStorage.clear()
        delete axios.defaults.headers.common.Authorization
        router.push({name:'Login'})
      }
      return Promise.reject(error)
    })
  },
}

export default { axiosConfig }
