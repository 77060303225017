import {reactive, ref, toRaw, watch, watchEffect} from 'vue';
import __, {cloneDeep} from 'lodash';

export function dataComposable() {
    const form = reactive({})
    let reference = {}
    const toSave = ref(false);

    function initEffect(referenceData) {
        watchEffect(() => {
            reference = cloneDeep(toRaw(referenceData))
            Object.assign(form, referenceData)
        })
    }

    watch(form, (oldValue, newValue) => {
        toSave.value = compare(reference, toRaw(newValue))
    }, {
        deep: true
    })

    function compare(referenceData, currentData) {
        // if (typeof referenceData !== 'object' || typeof currentData !== 'object') {
        //     return true;
        // }
        return !__.isEqual(JSON.parse(JSON.stringify(referenceData)), JSON.parse(JSON.stringify(currentData)));
    }

  function generateFormData(params, exceptions = []) {
    let formData = new FormData();
    for (let key in params) {
      if (exceptions.includes(key)) {
        continue;
      }
      if (params[key] !== null && typeof params[key] === 'object' && !(params[key] instanceof File)) {
        for (let subKey in params[key]) {
          if (exceptions.includes(subKey)) {
            continue;
          }
          formData.append(`${key}[${subKey}]`, params[key][subKey]);
        }
      } else if (Array.isArray(params[key]) && params[key].length) {
        params[key].forEach((value, index) => {
          if (value instanceof File) {
            formData.append(key + '[' + index + ']', value);
          }
        });
      } else {
        formData.append(key, params[key]);
      }
    }
    return formData;
  }


  return {initEffect, form, reference, toSave, compare, generateFormData}
}
