<template>
  <template v-if="status !== undefined && status !== null">

    <span v-if="typeof status === 'number'" :class="[getStatusClass, customClasses]">
      {{ status === 1 ? 'ACTIVE' : (status === 0 ? 'INACTIVE' : status.toString().toUpperCase()) }}
    </span>

    <span v-else-if="typeof status === 'string'" :class="[getStatusClass, customClasses]">
      {{ status.toUpperCase() }}
    </span>

    <span v-else-if="typeof status === 'boolean'" :class="[getStatusClass, customClasses]">
      {{ status === true ? 'ACTIVE' : (status === false ? 'INACTIVE' : status.toString().toUpperCase()) }}
    </span>
  </template>
  <template v-else>
    <span>Undefined</span>
  </template>
</template>



<script>
import {computed} from 'vue';

export default {
  name: 'BaseStatusBadge',
  props: {
    status: {
      type: [String, Number],
      default: null,
    },
    customClasses: {
      type: String
    }
  },
  setup(props) {
    const getStatusClass = computed(() => {
      if (typeof props.status === 'number' || typeof props.status === 'boolean') {
        if (props.status === 1 || props.status === true) {
          return 'badge text-bg-success text-white'; // Active status
        } else if (props.status === 0 || props.status === false) {
          return 'badge text-bg-dark-medium'; // Inactive status
        }
      } else if (typeof props.status === 'string') {
        const status = props.status.toLowerCase();
        switch (status) {
          case 'draft - balanced':
            return 'badge text-bg-dark-medium text-warning'; // Draft-Balanced status
          case 'draft - unbalanced':
            return 'badge text-bg-warning-light'; // Draft-Unbalanced status
          case 'draft - no entries':
            return 'badge text-bg-warning-medium'; // Draft-Unbalanced status
          case 'posted':
            return 'badge text-bg-success text-white'; // Posted status
          // case 'approved':
          //   return 'badge text-bg-success-light text-black'; // Approved
          // case 'prepared':
          //   return 'badge text-bg-info'; // Prepared
          // case 'noted':
          //   return 'badge text-bg-primary'; // Noted
          // case 'in revision':
          //   return 'badge text-bg-warning text-black'; // In revision status
          default:
            return 'badge text-bg-light'; // Default status
        }
      }
      return 'badge text-bg-light'; // Default status for unrecognized status values
    });

    return {
      getStatusClass
    }
  }
}

</script>

<style scoped>
/* You can add scoped styles here if needed */
</style>
