/**
 krbs_kras_fe
 11/24/23,12:56 am
 */

'use strict'
export const urlRequest = (url = '') => {
  return {
    validateEntries (payload) {
      return Object.entries(payload)
        .filter(([key, value]) => value !== undefined)
        .reduce((obj, [key, value]) => {
          obj[key] = value
          return obj
        }, {})
    },
    encoded (payload) {
      const endPoint = `${url}`
      let queryParams = ''
      if (Object.keys(payload)?.length > 0) {
        const entries = this.validateEntries(payload)
        queryParams = this.generateQuery(entries)
      }
      if (queryParams) {
        return `${endPoint}?${queryParams}`
      }
      return endPoint
    },

    generateQuery (queryObj = {}, parentKey = null) {
      if (Object.keys(queryObj).length <= 0) {
        return null
      }
      return Object.keys(queryObj)
        .map(key => {
          const value = queryObj[key]
          if (typeof value === 'object' && value !== null) {
            return this.generateQuery(value, parentKey ? `${parentKey}[${key}]` : key)
          } else {
            const encodedKey = parentKey ? `${parentKey}[${key}]` : key
            return `${encodedKey}=${value}`
          }
        })
        .join('&')
    }
  }
}

export default {
  urlRequest
}
