<template>
    <main style="width: 100%;">
        <router-view />
    </main>
</template>
<script>
import {nextTick, onMounted, onUnmounted} from 'vue';
import {appComposables} from '@/composables/global.composables';

export default {
  name: 'AppMain',
  components: {},
  setup () {
    onMounted(async() => {
      await nextTick(() => {
      })
    })
    onUnmounted(() => {})
    return {}
  },
}
</script>

<style scoped>

</style>
