import {nextTick, reactive, ref} from "vue";
import {appAlert, appAlertRequestError} from "@/composables/alert.composables";
import {userModule} from "@/store/user.module";
import {storeToRefs} from "pinia";
import {dataComposable} from "@/composables/data.composable";
import router from "@/router";
import {route} from "@/services/uri.service";
import axios from "axios";
import {authModule} from "@/store/auth.module";
import {applicationAlert} from "@/composables/application.alerts";


export function useUserComposable() {
  const useUserModule = userModule();
  const userAuthModule = authModule();
  const {generateFormData} = dataComposable();

  const userObj = {
    hash_id: null,
    first_name: '',
    last_name: '',
    middle_name: '',
    suffix_name: '',
    username: '',
    email: '',
    status: true,
    password_activated: true,
    file: null,
  }

  const userActiveProfile = ref('');

  const {
    getResource
  } = storeToRefs(useUserModule);


  const user = reactive({...userObj})

  async function createUser() {
    if (await applicationAlert().confirm('Create User', 'Do you want to add this new user?')) {
      useUserModule.$patch({
        newResource: {...user}
      })
      let response = null;
      if (user.file !== null && user.file instanceof File) {
        let formData = generateFormData(user);
         response = await useUserModule.createRecord(formData);
      } else {
         response = await useUserModule.createRecord();
      }
      if (response){
        await applicationAlert().success('New User Added', 'You have successfully added this user record.');
        router.push({name: 'IndexUser'})
      }
      return response;
    }
    return false;
  }

  async function getUser(userId) {
    let response = await useUserModule.getRecord(userId)
    if (response) {
      Object.assign(user, {...getResource.value})
      user.status = Boolean(user.status)
      user.password_activated = Boolean(user.password_activated)
      return true;
    }
    return false;
  }

  async function updateUser(isFileInstanceFound) {
    if (await applicationAlert().confirm("Update User", "Are you sure you want to update this user?")) {
      useUserModule.$patch({
        resource: {...user}
      });

      let response = null;

      if (isFileInstanceFound) {
        let formData = generateFormData(user, ['files', 'active_profile']);
        response = await useUserModule.updateRecord(formData.get('hash_id'), formData);

      } else {
        /*
          Remove the files, and active_profile attributes in the user object
          because we don't want to include it in the request body
         */
        useUserModule.$patch((state) => {
          delete state.resource.files;
          delete state.resource.active_profile;
        })
        response = await useUserModule.updateRecord();
      }

      if (response) {

        Object.assign(user, {...getResource.value})
        user.status = Boolean(user.status)
        user.password_activated = Boolean(user.password_activated)

        await applicationAlert().success('User Updated', 'You have successfully updated this user.');
      }
      return response;
    }
    return false;
  }

  //TODO -> Delete User

  async function uploadUserAvatar(userId) {
    const payload = {
      file: user.file
    }
    let formData = generateFormData(payload);
    const changeAvatar = route.user().uploadAvatar(userId)

    return await axios.post(changeAvatar, formData).then((r) => {
      if (r) {
        let responseData = r.data.data
        Object.assign(user, responseData)
        applicationAlert().success('User Avatar', 'You have successfully changed the user avatar')
        return true;
      }
      return false;
    }).catch((er) => {
      appAlertRequestError(er).response();
    })
  }

  async function setUserAvatar(userId, fileHashId) {

    const payload = {
      file_hash_id: fileHashId.value
    }

    const setAvatar = route.user().setAvatar(userId);

    return await axios.post(setAvatar, payload).then((r) => {
      if (r) {
        userAuthModule.$patch((state) => {
          state.userAuth.link = r.data.data.link;
        })
        applicationAlert().success('User Avatar Updated', 'You have successfully updated the user avatar.')
        return true;
      }
    }).catch((er) => {
      appAlertRequestError(er).response();
    })
  }

  async function deleteUserAvatar(userId, fileHashId) {
    const payload = {
      'file_hash_id': fileHashId
    }

    const deleteAvatar = route.user().deleteAvatar(userId);

      return await axios.delete(deleteAvatar, {
        data: payload
      }).then(async (r) => {
        if (r) {
          await applicationAlert().success('User Avatar Deleted', 'You have successfully deleted the user avatar.')
          await getUser(userId);
          await nextTick();
          return true;
        }
      }).catch((er) => {
        appAlertRequestError(er).response();

      })
  }

  return {
    user,
    userActiveProfile,
    createUser,
    getUser,
    updateUser,
    uploadUserAvatar,
    setUserAvatar,
    deleteUserAvatar
  }
}
