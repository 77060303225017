/**
 elite-jov
 2/5/24,11:05 am
 */
import {defineStore} from 'pinia'
import {parse, stringify} from 'zipson/lib'

export const sidebarModule = defineStore('sidebar-module', {
    state: () => ({
        sidebarExpanded: false,
        sidebarOnTop: false,
        sidebarHoverable: true,
    }),
    getters: {},
    actions: {
        expandSidebar() {
            this.sidebarExpanded = !this.sidebarExpanded;
        },

        toggleSidebarByClick(){
            this.expandSidebar();
            this.sidebarOnTop = false;
            this.sidebarHoverable = !this.sidebarExpanded;
        },
        hoverExpand() {
            if (this.sidebarHoverable) {
                this.sidebarExpanded = true;
                this.sidebarOnTop = true;
            }
        },
        hoverRemoveExpand(){
            if (this.sidebarHoverable) {
                this.sidebarExpanded = false;
                this.sidebarOnTop = false;
            }
        },
        hideSidebar(){
            this.sidebarExpanded = false;
            this.sidebarOnTop = false;
            this.sidebarHoverable = true;
        }
    },
    persist: {
        debug: false,
        storage: localStorage,
        paths: [],
        serializer: {
            deserialize: parse,
            serialize: stringify,
        },
        beforeRestore: () => {

        },
        afterRestore: () => {

        },
    }
})

