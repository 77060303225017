<template>
    <div class="d-flex gap-2 text-center align-middle">
        <button v-if="buttonType === 'add'"
                :class="[customButtonClasses]"
                class="btn badge text-bg-success text-white"
                v-bind="$attrs"
                @click="buttonEvent('add')">
            Add
        </button>
        <button v-if="buttonType === 'save'"
                :class="[customButtonClasses]"
                class="btn badge text-bg-success text-white"
                v-bind="$attrs"
                @click="buttonEvent('save')">
            Save
        </button>
        <button v-if="buttonType === 'edit'"
                :class="[customButtonClasses]"
                class="btn badge text-bg-info"
                v-bind="$attrs"
                @click="buttonEvent('edit')">
            Edit
        </button>
        <button type="button" v-if="buttonType === 'delete'"
                :class="[customButtonClasses]"
                class="btn badge text-bg-danger"
                v-bind="$attrs"
                @click="buttonEvent('delete')">
            Delete
        </button>
        <button type="button" v-if="buttonType === 'view'"
                :class="[customButtonClasses]"
                class="btn badge text-bg-primary-medium"
                v-bind="$attrs"
                @click="buttonEvent('view')">
            View
        </button>
      <button type="button" v-if="buttonType === 'download'"
              :class="[customButtonClasses]"
              class="btn badge text-bg-success-light"
              v-bind="$attrs"
              @click="buttonEvent('view')">
        Download
      </button>
      <button type="button" v-if="buttonType === 'cancel'"
              :class="[customButtonClasses]"
              class="btn badge text-bg-warning"
              v-bind="$attrs"
              @click="buttonEvent('cancel')">
        Cancel
      </button>
        <button v-if="buttonType === 'custom'"
                :class="[customButtonClasses]"
                class="btn badge"
                @click="buttonEvent({ customButtonLabel })" v-bind="$attrs">
            {{ appComposables.ucFirstEachWord(customButtonLabel) }}
        </button>
    </div>
</template>
<script>
import {onMounted, onUnmounted, ref} from 'vue';
import {appComposables} from "../../composables/global.composables";

export default {
  name: 'BaseActionButtons',
  inheritAttrs: false,
  computed: {
    appComposables() {
      return appComposables
    }
  },
  emits : ['buttonEvent'],
  props: {
    buttonType: {
      type: String,
      required: true,
    },
    customButtonLabel: {
      type: String,
    },
    customButtonClasses: {
      type: String,
    },
  },
  setup(props, {emit}) {
    const buttonEvent = (e) => {
      emit('buttonEvent', e);
    };
    onMounted(() => {});
    onUnmounted(() => {});
    return {
      buttonEvent,
    };
  },
};
</script>

<style scoped>

</style>
