<template>
  <div id="sidenav-nav">

    <!--  logo-->
    <router-link :to="{name:'Home'}" href="#" class="text-decoration-none text-secondary">
      <div class="sidenav-logo">
        <img style="width: 40px !important;" src="@/assets/krbs.logo.png" alt="app-logo"
             :class="{'mx-auto': !isSidebarExpanded}">

        <div v-if="isSidebarExpanded" class="sidenav-company-name">
          <div style="font-size: 14px;">
            <!--         COMPANY NAME LINE 1 -->
            <div>
              "K" LINE RORO BULK SHIP
            </div>
            <!--          COMPANY NAME LINE 2 -->
            <div>
              MANAGEMENT CO., LTD.
            </div>
          </div>
          <div style="font-size: 11px;  margin-top: 2px;" class="fw-semibold">
            ACCOUNTING DATABASE SYSTEM
          </div>
        </div>
      </div>
    </router-link>
    <nav class="app-sidenav accordion app-sidenav-light" id="sidenavAccordion" :class="{ expanded: isSidebarExpanded }">
      <div class="app-sidenav-menu">
        <div class="nav flex-column">
          <div class="module-group">
            <div class="sidenav-menu-heading">
              M<span v-if="isSidebarExpanded">ain</span>
            </div>
            <div id="parent-dashboard" class="nav-link"
                 :class="{'active-parent-link' : appComposables.isRouteActive('Dashboard')}">
              <router-link :to="{name : 'Dashboard'}" href="#">
                <div class="module-container">
                  <div class="module-name-icon">
                    <font-awesome-icon class="sidenav-link-icon" icon="chart-simple"/>
                    <div class="module-name" v-if="isSidebarExpanded"> Dashboard</div>
                  </div>
                </div>
              </router-link>
            </div>
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
               data-bs-target="#collapse-master-file" aria-expanded="false"
               aria-controls="collapse-reports" id="parent-masterfile"
               :class="{'active-parent-link' : appComposables.isRouteActive('MasterFile')}">
              <div class="module-container">

                <div class="module-name-icon">
                  <font-awesome-icon class="sidenav-link-icon" icon="columns"/>
                  <div class="module-name" v-if="isSidebarExpanded">Master File</div>
                </div>

                <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                  <font-awesome-icon icon="angle-down"/>
                </div>
              </div>

            </a>
            <div class="collapse" id="collapse-master-file" aria-labelledby="headingOne"
                 data-bs-parent="#sidenavAccordion">
              <nav class="app-sidenav-menu-nested nav">
                <div class="nav-link">
                  <router-link
                      :to="{name : 'ChartOfAccountList'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Chart of Accounts</span>
                    <span v-else>COA</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link
                      :to="{name : 'AccountFiles'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Account Files</span>
                    <span v-else>AF</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link
                      :to="{name : 'InitialEndingBalance'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Initial Ending Balance</span>
                    <span v-else>IEB</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link
                      :to="{name : 'PayeePayorList'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Payee/Payor List</span>
                    <span v-else>PL</span>
                  </router-link>
                </div>
              </nav>
            </div>
            <!--   Transaction Module  -->
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
               data-bs-target="#collapse-transactions"
               aria-expanded="false"
               aria-controls="collapse-vouchers"
               :class="{'active-parent-link' : appComposables.isRouteActive('Transactions')}">
              <div class="module-container">
                <div class="module-name-icon">
                  <font-awesome-icon style="margin-left: 3px;" class="sidenav-link-icon"
                                     icon="file-alt"/>
                  <div class="module-name" v-if="isSidebarExpanded">Transaction</div>
                </div>
                <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                  <font-awesome-icon icon="angle-down"/>
                </div>
              </div>
            </a>
            <div class="collapse" id="collapse-transactions" aria-labelledby="headingOne"
                 data-bs-parent="#sidenavAccordion">
              <nav class="app-sidenav-menu-nested nav">
                <div class="nav-link">
                  <router-link :to="{name :'TransactionList',params:{type:'cv'}}" href="#">
                    <span class="sub-link" v-if="isSidebarExpanded">Check Disbursement</span>
                    <span v-else>CV</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link :to="{name :'TransactionList',params:{type:'cr'}}" href="#">
                    <span class="sub-link" v-if="isSidebarExpanded">Cash Receipt</span>
                    <span v-else>CR</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link :to="{name :'TransactionList',params:{type:'jv'}}" href="#">
                    <span class="sub-link" v-if="isSidebarExpanded">Journal Voucher</span>
                    <span v-else>JV</span>
                  </router-link>
                </div>

                <!--<a class="nav-link" href="#">-->
                <!--<font-awesome-icon class="sidenav-link-icon" icon="file-alt"/>-->
                <!--Transactions-->
                <!--</a>-->

                <!--<a class="nav-link" href="#">-->
                <!--<font-awesome-icon class="sidenav-link-icon" icon="file-alt"/>-->
                <!--Update Entry-->
                <!--</a>-->
              </nav>
            </div>
<!--            <div class="nav-link"-->
<!--                 :class="{'active-parent-link' : appComposables.isRouteActive('AccountClosing')}">-->
<!--              <router-link :to="{name : 'AccountClosing'}" href="#">-->
<!--                <div class="module-container">-->
<!--                  <div class="module-name-icon">-->
<!--                    <font-awesome-icon style="margin-left: 2px;" class="sidenav-link-icon" icon="lock"/>-->
<!--                    <div class="module-name" v-if="isSidebarExpanded"> Account Closing </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </router-link>-->
<!--            </div>-->
          </div>
          <!-- Reports -->
          <div class="module-group">
            <div class="sidenav-menu-heading">
              R<span v-if="isSidebarExpanded">eports</span>
            </div>
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
               data-bs-target="#collapse-reports" aria-expanded="false"
               aria-controls="collapse-reports"
               :class="{'active-parent-link' : appComposables.isRouteActive('Reports')}">
              <div class="module-container">
                <div class="module-name-icon">
                  <font-awesome-icon class="sidenav-link-icon" icon="columns"/>
                  <div class="module-name" v-if="isSidebarExpanded">Reports</div>
                </div>
                <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                  <font-awesome-icon icon="angle-down"/>
                </div>
              </div>
            </a>
            <div class="collapse" id="collapse-reports" aria-labelledby="headingOne"
                 data-bs-parent="#sidenavAccordion">
              <nav class="app-sidenav-menu-nested nav">
                <div class="nav-link">
                  <router-link :to="{name :'BalanceSheet'}" href="#">
                    <span class="sub-link" v-if="isSidebarExpanded">Balance Sheet</span>
                    <span v-else>BS</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link :to="{name :'IncomeStatement'}" href="#">
                    <span class="sub-link" v-if="isSidebarExpanded">Income Statement</span>
                    <span v-else>IS</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link :to="{name :'TrialBalance'}" href="#">
                    <span class="sub-link" v-if="isSidebarExpanded">Trial Balance</span>
                    <span v-else>TB</span>
                  </router-link>
                </div>
                <!--                                <div class="nav-link">-->
                <!--                                    <router-link :to="{name:'CheckVoucherReports'}" href="#">-->
                <!--                                        <span class="sub-link" v-if="isSidebarExpanded">Check Disbursement</span>-->
                <!--                                        <span v-else>CV</span>-->
                <!--                                    </router-link>-->
                <!--                                </div>-->
                <!--                                -->
                <!--                                <div class="nav-link">-->
                <!--                                    <router-link :to="{name:'CashReceiptReports'}" href="#">-->
                <!--                                        <span class="sub-link" v-if="isSidebarExpanded">Cash Receipt</span>-->
                <!--                                        <span v-else>CR</span>-->
                <!--                                    </router-link>-->
                <!--                                </div>-->
                <!--                                <div class="nav-link">-->

                <!--                                    <router-link :to="{name:'JournalVoucherReports'}" href="#">-->
                <!--                                        <span class="sub-link" v-if="isSidebarExpanded">Journal Voucher</span>-->
                <!--                                        <span v-else>JV</span>-->
                <!--                                    </router-link>-->
                <!--                                </div>-->
                <!--                                <div class="nav-link">-->
                <!--                                    <router-link :to="{name:'DetailTrialBalance'}" href="#">-->
                <!--                                        <span class="sub-link" v-if="isSidebarExpanded">Detail Trial Balance</span>-->
                <!--                                        <span v-else>DTB</span>-->
                <!--                                    </router-link>-->
                <!--                                </div>-->
                <!--                                <div class="nav-link">-->
                <!--                                    <router-link :to="{name:'LedgerReport'}" href="#">-->
                <!--                                        <span class="sub-link" v-if="isSidebarExpanded">Ledger Report</span>-->
                <!--                                        <span v-else>LR</span>-->
                <!--                                    </router-link>-->
                <!--                                </div>-->
                <!--                                <a class="nav-link"-->
                <!--                                   data-bs-toggle="collapse"-->
                <!--                                   data-bs-target="#collapse-ledger-reports"-->
                <!--                                   data-bs-parent="#sidenavAccordion">-->
                <!--                                    <font-awesome-icon class="app-nav-link-icon" icon="file"/>-->
                <!--                                    <span  style="font-size: 13px;">-->
                <!--                                        Ledger Reports-->
                <!--                                    </span>-->
                <!--                                </a>-->
                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
                   :class="{'active-parent-link' : appComposables.isRouteActive('VoucherReports')}"
                   data-bs-target="#collapse-vouchers-report" aria-expanded="false"
                   aria-controls="collapse-drafts">
                  <div class="module-container">
                    <div class="module-name-icon">
                      <span class="sub-link" v-if="isSidebarExpanded">Vouchers</span>
                      <span v-else>V</span>
                    </div>
                    <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                      <font-awesome-icon icon="angle-down"/>
                    </div>
                  </div>
                </a>
                <div class="collapse" id="collapse-vouchers-report" aria-labelledby="headingOne"
                     data-bs-parent="#sidenavAccordionPages" style="">
                  <nav class="nav app-sidenav-menu-nested"
                       :class="{ 'app-sidenav-menu-nested-expanded': isSidebarExpanded }">
                    <div class="nav-link">
                      <router-link :to="{name:'CheckVoucherReports'}">
                        <span class="sub-link-2" v-if="isSidebarExpanded">Check Disbursement</span>
                        <span v-else>CV</span>
                      </router-link>
                    </div>
                    <div class="nav-link">
                      <router-link :to="{name:'CashReceiptReports'}">
                        <span class="sub-link-2" v-if="isSidebarExpanded">Cash Receipt</span>
                        <span v-else>CR</span>
                      </router-link>
                    </div>
                    <div class="nav-link">
                      <router-link :to="{name:'JournalVoucherReports'}">
                        <span class="sub-link-2" v-if="isSidebarExpanded">Journal Voucher</span>
                        <span v-else>JV</span>
                      </router-link>
                    </div>
                  </nav>
                </div>
                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
                   :class="{'active-parent-link' : appComposables.isRouteActive('LedgerReports')}"
                   data-bs-target="#collapse-general-ledger" aria-expanded="false"
                   aria-controls="collapse-drafts">
                  <div class="module-container">
                    <div class="module-name-icon">
                      <span class="sub-link" v-if="isSidebarExpanded">Ledgers</span>
                      <span v-else>L</span>
                    </div>
                    <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                      <font-awesome-icon icon="angle-down"/>
                    </div>
                  </div>
                </a>
                <div class="collapse" id="collapse-general-ledger" aria-labelledby="headingOne"
                     data-bs-parent="#sidenavAccordionPages" style="">
                  <nav class="nav app-sidenav-menu-nested"
                       :class="{ 'app-sidenav-menu-nested-expanded': isSidebarExpanded }">
                    <div class="nav-link">
                      <router-link :to="{name:'GeneralLedger'}">
                        <span class="sub-link-2" v-if="isSidebarExpanded">General Ledger</span>
                        <span v-else>GL</span>
                      </router-link>
                    </div>
                    <div class="nav-link">
                      <router-link :to="{name:'SubsidiaryLedger'}">
                        <span class="sub-link-2" v-if="isSidebarExpanded">Subsidiary Ledger</span>
                        <span v-else>SL</span>
                      </router-link>
                    </div>
                    <div class="nav-link">
                      <router-link :to="{name:'JournalLedger'}">
                        <span class="sub-link-2" v-if="isSidebarExpanded">Journal Ledger</span>
                        <span v-else>JL</span>
                      </router-link>
                    </div>
                  </nav>
                </div>
              </nav>
            </div>
          </div>

          <!-- Company -->
          <div class="module-group">
            <div class="sidenav-menu-heading">
              C<span v-if="isSidebarExpanded">ompany</span>
            </div>
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
               data-bs-target="#collapse-company" aria-expanded="false"
               aria-controls="collapse-company"
               :class="{'active-parent-link' : appComposables.isRouteActive('CompanySettings')}">
              <div class="module-container">
                <div class="module-name-icon">
                  <font-awesome-icon class="sidenav-link-icon" icon="building"/>
                  <div class="module-name" v-if="isSidebarExpanded">Company</div>
                </div>
                <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                  <font-awesome-icon icon="angle-down"/>
                </div>
              </div>
            </a>
            <div class="collapse" id="collapse-company" aria-labelledby="headingOne"
                 data-bs-parent="#sidenavAccordion">
              <nav class="app-sidenav-menu-nested nav">
                <div class="nav-link">
                  <router-link
                      :to="{name : 'IndexCompanySettings'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Profile</span>
                    <span v-else>P</span>
                  </router-link>
                </div>
              </nav>
            </div>
          </div>

          <!-- Logs -->
          <div class="module-group">
            <div class="sidenav-menu-heading">
              L<span v-if="isSidebarExpanded">ogs</span>
            </div>
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
               data-bs-target="#collapse-monitoring" aria-expanded="false"
               aria-controls="collapse-monitoring"
               :class="{'active-parent-link' : appComposables.isRouteActive('MonitoringActivity')}">
              <div class="module-container">
                <div class="module-name-icon">
                  <font-awesome-icon class="sidenav-link-icon" icon="history"/>
                  <div class="module-name" v-if="isSidebarExpanded">Monitoring</div>
                </div>
                <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                  <font-awesome-icon icon="angle-down"/>
                </div>
              </div>
            </a>

            <div class="collapse" id="collapse-monitoring" aria-labelledby="headingOne"
                 data-bs-parent="#sidenavAccordion">
              <nav class="app-sidenav-menu-nested nav">
                <div class="nav-link">
                  <router-link
                      :to="{name : 'TransactionLogs'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Transactions</span>
                    <span v-else>T</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link :to="{name : 'LoginLogs'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Login</span>
                    <span v-else>L</span>
                  </router-link>
                </div>

                <div class="nav-link">
                  <router-link :to="{name : 'ChangePasswordLogs'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Change Password</span>
                    <span v-else>C</span>
                  </router-link>
                </div>
              </nav>
            </div>
          </div>


          <!-- Admin -->
          <div class="module-group">
            <div class="sidenav-menu-heading">
              A<span v-if="isSidebarExpanded">dmin</span>
            </div>
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
               data-bs-target="#collapse-settings" aria-expanded="false"
               aria-controls="collapse-settings"
               :class="{'active-parent-link' : appComposables.isRouteActive('Settings')}">
              <div class="module-container">
                <div class="module-name-icon">
                  <font-awesome-icon class="sidenav-link-icon" icon="gears"/>
                  <div class="module-name" v-if="isSidebarExpanded">Settings</div>
                </div>
                <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                  <font-awesome-icon icon="angle-down"/>
                </div>
              </div>
            </a>
            <div class="collapse" id="collapse-settings" aria-labelledby="headingOne"
                 data-bs-parent="#sidenavAccordion">
              <nav class="app-sidenav-menu-nested nav">
                <div class="nav-link">
                  <router-link
                      :to="{name : 'IndexUser'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Users</span>
                    <span v-else>U</span>
                  </router-link>
                </div>
                <div class="nav-link">
                  <router-link :to="{name : 'IndexOtpSettings'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Otp Settings</span>
                    <span v-else>OTP</span>
                  </router-link>
                </div>
                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse"
                   data-bs-target="#collapse-drafts" aria-expanded="false"
                   aria-controls="collapse-drafts">
                  <div class="module-container">
                    <div class="module-name-icon">
                      <span class="sub-link" v-if="isSidebarExpanded">Drafts</span>
                      <span v-else>DR</span>
                    </div>
                    <div v-if="isSidebarExpanded" class="app-sidenav-collapse-arrow">
                      <font-awesome-icon icon="angle-down"/>
                    </div>
                  </div>
                </a>
                <div class="collapse" id="collapse-drafts" aria-labelledby="headingOne"
                     data-bs-parent="#sidenavAccordionPages" style="">
                  <nav class="nav app-sidenav-menu-nested"
                       :class="{ 'app-sidenav-menu-nested-expanded': isSidebarExpanded }">
                    <div class="nav-link">
                      <router-link :to="{name:'DraftTemplateList'}">
                        <span class="sub-link-2" v-if="isSidebarExpanded">Templates</span>
                        <span v-else>TP</span>
                      </router-link>
                    </div>
                  </nav>
                </div>
                <!-- Todo Collection Type -->
                 <div class="nav-link">
                     <router-link :to="{name:'CollectionType'}">
                         <span class="sub-link" v-if="isSidebarExpanded">Collection Type</span>
                         <span v-else>CT</span>
                     </router-link>
                 </div>
                <!-- Todo Disbursement Type -->
                <div class="nav-link">
                  <router-link :to="{name:'DisbursementType'}">
                    <span class="sub-link" v-if="isSidebarExpanded">Disbursement Type</span>
                    <span v-else>DT</span>
                  </router-link>
                </div>
                <!-- Todo Position -->
                  <div class="nav-link">
                      <router-link :to="{name:'Position'}">
                          <span class="sub-link" v-if="isSidebarExpanded">Position</span>
                          <span v-else>P</span>
                      </router-link>
                  </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import {computed, nextTick, onMounted, onUnmounted, reactive, ref, toRaw, watch} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {globalModule} from '@/store/global.module';
import {storeToRefs} from 'pinia';
import __ from 'lodash';
import {appComposables} from '@/composables/global.composables';
import {useRoute} from 'vue-router';

export default {
  name: 'AppSideNavLink',
  computed: {
    appComposables() {
      return appComposables;
    },
  },
  components: {FontAwesomeIcon},
  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const useGlobalModule = globalModule();
    const route = useRoute();
    const isRouteActive = (routeName, exceptions = []) => {
      const routes = route.matched;
      const active = reactive({value: false});
      routes.forEach(route => {
        if (route.name === routeName) {
          active.value = true;
        }
      });
      routes.forEach(route => {
        if (exceptions.includes(route.name)) {
          active.value = false;
        }
      });

      return active;
    };

    const toggleActiveParentLink = (id) => {
      // Remove 'active-parent-link' class from all elements
      document.querySelectorAll('.active-parent-link').forEach(element => {
        element.classList.remove('active-parent-link');
      });
      const element = document.getElementById(id);
      if (element) {
        element.classList.add('active-parent-link');
      } else {
        console.warn(`Element with ID ${id} not found`);
      }
    };
    /** Method  **/

    /** Watch **/
    watch(
        () => props.isSidebarExpanded,
        (newValue) => {
          const sidebarToggle = localStorage.getItem('app|sidebar-toggle');

          const navLinks = document.getElementsByClassName('nav-link');
          const moduleContainers = document.getElementsByClassName('module-container');
          const sidenavLinkIcons = document.getElementsByClassName('sidenav-link-icon');
          const sidenavMenuHeading = document.getElementsByClassName('sidenav-menu-heading');

          for (let i = 0; i < navLinks.length; i++) {
            if (newValue) {
              navLinks[i].classList.add('expanded');
            } else {
              navLinks[i].classList.remove('expanded');
            }
          }

          for (let i = 0; i < moduleContainers.length; i++) {
            if (newValue) {
              moduleContainers[i].classList.add('expanded');
            } else {
              moduleContainers[i].classList.remove('expanded');
            }
          }

          for (let i = 0; i < sidenavLinkIcons.length; i++) {
            if (newValue) {
              sidenavLinkIcons[i].classList.add('expanded');
            } else {
              sidenavLinkIcons[i].classList.remove('expanded');
            }
          }

          for (let i = 0; i < sidenavMenuHeading.length; i++) {
            if (newValue) {
              sidenavMenuHeading[i].classList.add('expanded');
            } else {
              sidenavMenuHeading[i].classList.remove('expanded');
            }
          }
        },
    );

    /** END Method  **/
    onMounted(() => {
      nextTick(() => {
      });
    });

    onUnmounted(() => {
      //index
      //store
      //show
      //update
      //delete
    });

    onUnmounted(() => {
    });
    return {
      toggleActiveParentLink,
      isRouteActive,
      route,
    };
  },
};
</script>

<style scoped>

</style>
