/**
 krbs_kras_fe
 11/23/23,11:54 am
 */
'use strict';
import Swal from 'sweetalert2';
import {appComposables} from "@/composables/global.composables";
import {applicationAlert} from "@/composables/application.alerts";

export const appAlert = () => {
  return {
    /**
     * @param type
     * @param title
     * @param message
     */
    error: (type = '', title = 'foo title', message = '') => {
      switch (type) {
        case 'fields':
          message = message || 'Please fill all the required fields.';
          break;
        case 'zero-amount':
          message = message || 'Debit and Credit amounts cannot be zero.';
          break;
        case 'invalid-amount':
          message = message || 'Please enter a valid amount.';
          break;
        case 'not-balanced':
          message = message || 'Debit and Credit amounts are not balanced.';
          break;
        case 'no-changes':
          message = message || 'Data has no changes.';
          break;
        case 'empty-entry':
          message = message || 'An entry has no debit or credit amount'
      }
      Swal.fire({
        title: `${title}`,
        icon: 'error',
        text: `${message}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn swal-btn-danger',
        },
      }).then((r) => {
        return r.result;
      });
    },

    /**
     * @param title
     * @param action
     * @param resource
     * @param message
     * @param icon
     */
    question: async (action = '', resource = '', title = 'foo title', message = 'foo message', icon = '') => {
      let i = icon === '' ? 'question' : icon;
      action = appComposables.lcFirstEachWord(action);
      resource = appComposables.lcFirstEachWord(resource);
      let formattedAction = appComposables.ucFirst(action);
      let formattedResource = appComposables.ucFirstEachWord(resource);

      if (action === 'logout') {
        message = 'Are you sure you want to logout?'
        title = `${formattedAction} ${formattedResource}`;
      } else if (action === 'leaving') {
        if (!resource) {
          formattedResource = 'Module';
        }
        message = 'All changes made will be disregarded. Do you still want to leave?'
        title = `${formattedAction} ${formattedResource}`;
      } else if (action) {
        message = `Do you want to ${action} this ${resource} ${message}?`;
        title = `${formattedAction} ${formattedResource}`;
      }

      return await Swal.fire({
        title: `${title}`,
        icon: `${i}`,
        text: `${message}`,
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn swal-btn-primary',
          cancelButton: 'btn swal-btn-secondary ms-2',
        },
      }).then((r) => {
        return r.value;
      });
    },

    /**
     * @param title
     * @param message
     */
    warning: (title = 'foo title', message = 'foo message', html = '') => {
      Swal.fire({
        title: `${title}`,
        icon: 'warning',
        text: `${message}`,
        html: `${html}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: 'btn swal-btn-warning',
        },
        buttonsStyling: false,
      }).then((r) => {
        return r.value;
      });
    },

    /**
     * @param title
     * @param message
     */
    info: (title = 'foo title', message = 'foo message') => {
      return Swal.fire({
        title: `${title}`,
        icon: 'info',
        text: `${message}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn swal-btn-primary',
        },
      }).then((r) => {
        return r.value;
      });
    },

    /**
     * @param action
     * @param resource
     * @param title
     * @param message
     */

    success: async (action = '', resource = '', title = 'foo title', message = 'foo message') => {
      action = appComposables.lcFirstEachWord(action);
      resource = appComposables.lcFirstEachWord(resource);
      let formattedAction = appComposables.ucFirst(action);
      let formattedResource = appComposables.ucFirstEachWord(resource);
      let pastTense = ''; // Initialize pastTense here

      if (action === 'logout') {
        message = 'You have logged out successfully.';
        title = `${formattedAction}`;
      } else if (action === 'login') {
        message = 'You have logged in successfully.';
        title = `${formattedAction}`;
      } else if (action) {
        if (action === 'delete'){
          pastTense = 'deleted';
        } else if (action === 'update') {
          pastTense = 'updated';
        } else if (action === 'save') {
          pastTense = 'saved';
        } else if (action === 'create') {
          pastTense = 'created';
        } else if (action === 'add') {
          pastTense = 'added';
        }

        // Use pastTense after it's initialized
        message = `You have ${pastTense} this ${formattedResource} successfully.`;
        let formattedPastTense = appComposables.ucFirstEachWord(pastTense);
        title = `${formattedPastTense} ${formattedResource}`;
      }

      return await Swal.fire({
        title: `${title}`,
        icon: 'success',
        text: `${message}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn swal-btn-primary',
          cancelButton: '',
        },
      }).then((r) => {
        return r.value;
      });
    },

    /**
     * @param action
     * @param resource
     * @param confirmButtonText
     * @param icon
     * @param title
     * @param message
     */
    confirm: async (
      action = '', resource = '', confirmButtonText = `Yes, ${action}`, icon = 'question',
      title = 'foo title', message = 'foo message') => {
      action = appComposables.lcFirstEachWord(action);
      resource = appComposables.lcFirstEachWord(resource);
      let formattedAction = appComposables.ucFirst(action);
      let formattedResource = appComposables.ucFirstEachWord(resource);

      if (action === 'leaving'){
        confirmButtonText = 'Yes, continue'
      }

      if (action === 'logout') {
        message = 'Are you sure you want to logout?'
        title = `${formattedAction} ${formattedResource}`;
      } else if (action === 'leaving') {
        message = 'All changes made will be disregarded. Do you still want to leave?'
        title = `${formattedAction} ${formattedResource}`;
      } else if (action) {
        message = `Do you want to ${action} this ${resource}?`;
        title = `${formattedAction} ${formattedResource}`;
      }

      return await Swal.fire({
        title: `${title}`,
        icon: `${icon}`,
        text: `${message}`,
        showCancelButton: true,
        confirmButtonText: `${confirmButtonText}`,
        scrollbarPadding: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        allowEnterKey : true,
        focusCancel: false,
        customClass: {
          confirmButton: 'btn swal-btn-primary',
          cancelButton: 'btn swal-btn-secondary ms-2',
        },
      }).then((r) => {
        return r.isConfirmed;
      });
    },
    // confirm: async (
    //   title = 'foo title', message = 'foo message',
    //   confirmButtonText = 'Yes', icon = 'question') => {
    //   return await Swal.fire({
    //     title: `${title}`,
    //     icon: `${icon}`,
    //     text: `${message}`,
    //     showCancelButton: true,
    //     confirmButtonText: `${confirmButtonText}`,
    //     scrollbarPadding: false,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     buttonsStyling: false,
    //     customClass: {
    //       confirmButton: 'btn swal-btn-primary',
    //       cancelButton: 'btn swal-btn-secondary ms-2',
    //     },
    //   }).then((r) => {
    //     return r.isConfirmed;
    //   });
    // },

    /**
     * @param title
     * @param message
     */
    noContent: (title = 'foo title', message = 'foo message') => {
      Swal.fire({
        title: `${title}`,
        icon: 'info',
        text: `${message}`,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
          container: '',
          confirmButton: 'btn swal-btn-primary',
        },
      }).then((r) => {
        return r.value;
      });
    },
    loading: () => {
      Swal.showLoading()
    },
  };
};

export const appAlertRequestError = (er) => {
  return {
    parseErrors(errors) {
      let erMsg = [];
      Array.isArray(errors) ? Object.keys(errors).forEach(column => erMsg[column] = errors[column].join(', ')) : erMsg = [];

      return {errors: erMsg};
    },
    response: () => {
      let msgHtml = '';
      let msgHtmlContainer = '';
      let erMsgs = [];
      let devInfo = [];
      let traceMsg = [];
      let statusText = er.response?.statusText ?? '';
      // let statusMsg = er.response.status >= 500 ? er.message : er.response.data?.message;
      let statusMsg = er?.response?.data?.message;
      let errors = er?.response?.data?.errors ?? null;

      if (errors) {
        Object.keys(errors).forEach(column => {
          if (errors[column].length > 1) {
            errors[column].forEach(error=>{
              erMsgs.push(error);
            })
          } else {
            erMsgs.push(errors[column][0]);
          }
        })
        if (erMsgs) {
          Object.keys(erMsgs).forEach((key, index) => {
            msgHtml += `<p class="text-start">${index + 1}. ${erMsgs[key]}</p>`;
          });
          msgHtmlContainer =
            `<div style="max-height: 40vh !important;" class="overflow-y-auto text-start align-middle">
                  ${msgHtml}
             </div>`;
        }
        if (process.env.VUE_APP_NODE === 'local') {
          if (er.response.data.hasOwnProperty('code_line')) {
            devInfo['code_line'] = er.response.data.code_line;
          }

          if (er.response.data.hasOwnProperty('file')) {
            devInfo['file'] = er.response.data.file;
          }

          if (er.response.data.hasOwnProperty('hint')) {
            devInfo['code_line'] = er.response.data.hint;
          }
        }
      } else {
        msgHtmlContainer =
          //   `<div class="row text-start align-middle">
          //     <div class="col-lg-12">
          //       <p class="text-left">${statusMsg}</p>
          //     </div>
          // </div>`;
          `<p class="text-start">${statusMsg}</p>`;
      }
      let sample = `<p>Sample</p>`;
      applicationAlert().warning(statusText, erMsgs, msgHtmlContainer);
    }
  }
};
export default {
  appAlertRequestError
};
